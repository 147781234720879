import {
  GET_STOCKS_HISTORY,
  GET_STOCKS_HISTORY_SUCCESS,
  GET_STOCKS_HISTORY_FAIL,
} from "./actionTypes"

const initialState = {
  historyData: { data: [] },
  loading: false,
  error: null,
}

const stocksHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STOCKS_HISTORY:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case GET_STOCKS_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        historyData: action.payload,
      }

    case GET_STOCKS_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default stocksHistoryReducer 