// StyledComponents.js
import styled from "styled-components"
import { Button, Input } from "reactstrap" // ここでは、reactstrapからButtonとInputをimportしていますが、
// 別のライブラリや自分で定義したコンポーネントを使っても構いません。

export const StyledButton1 = styled(Button)`
  margin-right: 5px;
  width: 20%;
`

export const StyledButton2 = styled(Button)`
  marginbottom: "10px";
  width: 100%;
`

export const StyledButton3 = styled(Button)`
  marginbottom: "10px";
  width: 40%;
  flex-shrink: 0; // この要素が縮小しないようにします。
`

export const StyledButton4 = styled(Button)`
  marginbottom: "10px";
  width: 50%;
  flex-shrink: 0; // この要素が縮小しないようにします。
`

export const StyledInput1 = styled(Input)`
  margin-right: 5px;
  width: 60%;
  text-align: center;
  font-weight: bold; // 文字を太文字にします
  font-size: 1.2em; // 文字サイズを少し大きくします
`

export const StyledButtonRow = styled.div`
  display: flex;
  flexdirection: "row";
  justifycontent: "space-between";
  width: 100%;
`

export const StyledButtonRow1 = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
`

export const StyledActionBtn = styled(Button)`
  margin-right: 5px;
  width: 45%; // 100%のスペースを均等に2つのボタンで分けるために45%と設定しています。
`

export const StyledDiv1 = styled.div`
  width: 60%; // 幅を300ピクセルに固定します
  flex-shrink: 0; // この要素が縮小しないようにします
  font-weight: bold; // 文字を太文字にします
  font-size: 1.2em; // 文字サイズを少し大きくします
`

export const StyledTableCell = styled.td`
  flex: 1;
  display: flex;
  align-items: flex-start;
  text-align: left;
  padding: 0.75rem;
  min-height: 54px;
  
  @media (max-width: 768px) {
    &.product-name {
      min-width: 160px;
      max-width: calc(100% - 240px);
      white-space: normal;
      word-break: break-word;
      text-align: left;
      justify-content: flex-start;
      padding: 0.75rem 0.5rem;
      align-items: flex-start;
      flex-direction: column;
      flex: 1;
      position: relative;

      > span:first-child {
        position: absolute;
        left: -0.25rem;
        top: 0.75rem;
      }

      > span:not(:first-child) {
        padding-left: 0.75rem;
      }
    }
  }

  @media (max-width: 480px) {
    &.product-name {
      min-width: 120px;
      max-width: calc(100% - 180px);
      padding: 0.75rem 0.5rem;
    }
  }
`

export const StyledTableHeader = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0.75rem;
  text-align: left;
  font-weight: bold;
  flex: 1;
  
  &.product-name {
    flex: 1;
    min-width: 160px;
    padding-left: 2.2rem;
  }
  
  &.centered {
    width: 100px;
    text-align: center;
    flex: none;
    justify-content: center;
  }

  @media (max-width: 768px) {
    &.product-name {
      min-width: 160px;
      max-width: calc(100% - 240px);
      padding: 0.75rem 0.5rem;
      flex: 1;
    }

    &.centered {
      width: 80px;
      padding: 0.75rem 0.25rem;
      flex: none;
    }
  }

  @media (max-width: 480px) {
    &.product-name {
      min-width: 120px;
      max-width: calc(100% - 180px);
      padding: 0.75rem 0.5rem;
      
    }

    &.centered {
      width: 60px;
      padding: 0.75rem 0.125rem;
    }
  }
`

export const StyledValueCell = styled.div`
  width: 100px;
  text-align: center;
  padding: 0.75rem 0;
  min-height: 54px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 768px) {
    width: 80px;
    padding: 0.75rem 0.25rem;
    flex: none;
  }

  @media (max-width: 480px) {
    width: 60px;
    padding: 0.75rem 0.125rem;
  }
`
