import { get } from "../api_helper"
import * as url from "../url_helper"

export const getStocksHistory = async (
    locId
) => {
  try {
    const queryParams = new URLSearchParams()

    if (locId) {
      queryParams.append("loc_id", locId)
    }

    const response = await get(`${url.GET_STOCKS_HISTORY}?${queryParams.toString()}`)
    return { data: response }
  } catch (error) {
    throw error
  }
}

function formatDate(date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
} 