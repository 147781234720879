import { takeLatest, put, call } from "redux-saga/effects"
import { GET_STOCKS_HISTORY } from "./actionTypes"
import { getStocksHistorySuccess, getStocksHistoryFail } from "./actions"
import { getStocksHistory } from "../../helpers/api/stocksHistoryApi"

function* fetchStocksHistory({ payload: { locId } }) {
  try {
    const response = yield call(getStocksHistory, locId)
    yield put(getStocksHistorySuccess(response.data))
  } catch (error) {
    yield put(getStocksHistoryFail(error.message))
  }
}

function* stocksHistorySaga() {
  yield takeLatest(GET_STOCKS_HISTORY, fetchStocksHistory)
}

export default stocksHistorySaga 