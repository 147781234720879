import {
  GET_STOCKS_HISTORY,
  GET_STOCKS_HISTORY_SUCCESS,
  GET_STOCKS_HISTORY_FAIL,
} from "./actionTypes"

export const getStocksHistory = (locId) => ({
  type: GET_STOCKS_HISTORY,
  payload: { locId },
})

export const getStocksHistorySuccess = (data) => ({
  type: GET_STOCKS_HISTORY_SUCCESS,
  payload: data,
})

export const getStocksHistoryFail = (error) => ({
  type: GET_STOCKS_HISTORY_FAIL,
  payload: error,
}) 