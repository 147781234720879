import React, { useEffect, useState, useMemo } from 'react'
import { Container, Card, CardBody, Row, Col } from 'reactstrap'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import { useDispatch, useSelector } from 'react-redux'
import { startOfMonth, subDays, subMonths, subYears } from "date-fns"
import Breadcrumbs from '../../components/Common/Breadcrumb'
import CommonComponent from '../CommonComponent'
import LoadingOverlay from '../Common/LoadingOverlay'
import ErrorMessage from '../Common/ErrorMessage'
import { getStocksHistory } from '../../store/stocks-history/actions'

const StocksHistory = () => {
  const [goodsBrand, setGoodsBrand] = useState(null)
  const [locId, setLoc] = useState("")
  const [timePeriod, setTimePeriod] = useState("時")
  const [currentDate, setCurrentDate] = useState(new Date())
  const [vendor_id, setVendor] = useState(null)
  const [locGroup, setLocGroup] = useState(null)

  const dispatch = useDispatch()

  const { historyData, loading, error } = useSelector(state => ({
    historyData: state.stocksHistoryReducer.historyData || { data: [] },
    loading: state.stocksHistoryReducer.loading || false,
    error: state.stocksHistoryReducer.error || null
  }))

  const previousDate = useMemo(() => {
    if (timePeriod === "月") {
      return startOfMonth(subYears(currentDate, 1))
    } else if (timePeriod === "日") {
      return startOfMonth(currentDate)
    } else if (timePeriod === "時") {
      return subDays(currentDate, 1)
    } else if (timePeriod === "3か月") {
      return startOfMonth(subMonths(currentDate, 3))
    }
  }, [timePeriod, currentDate])

  useEffect(() => {
    if (locId) {
      dispatch(getStocksHistory(locId))
    }
  }, [locId, dispatch])

  // column_no と goods_name のマッピングを作成
  const columnGoodsNameMap = useMemo(() => {
    const map = {}
    if (!historyData?.data) return map
    historyData.data.forEach(item => {
      map[item.column_no] = item.goods_name
    })
    return map
  }, [historyData])

  const formattedData = useMemo(() => {
    if (!historyData.data) return []
    const groupedData = historyData.data.reduce((acc, item) => {
      const { column_no, column_stock_count, created_at } = item
      const date = new Date(created_at).toLocaleDateString()

      if (!acc[date]) acc[date] = { date }
      acc[date][`column_${column_no}`] = column_stock_count

      return acc
    }, {})

    return Object.values(groupedData)
  }, [historyData])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="システム管理" breadcrumbItem="在庫履歴" />
          {error && <ErrorMessage error={{ message: error }} />}
          <LoadingOverlay isLoading={loading} />

          <Row>
            <Col xs={12}>
              <Card className="bg-secondary">
                <CardBody>
                  <h4 className="card-title mb-4">検索条件</h4>
                  <CommonComponent
                    showGoodsBrand={false}
                    showDate={false}
                    showAll={false}
                    goodsBrand={goodsBrand}
                    setGoodsBrand={setGoodsBrand}
                    locId={locId}
                    setLoc={setLoc}
                    setLocGroup={setLocGroup}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    timePeriod={timePeriod}
                    setTimePeriod={setTimePeriod}
                    previousDate={previousDate}
                    vendor_id={vendor_id}
                    setVendor={setVendor}
                    past_loc={false}
                    showLocGroup={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">在庫推移</h4>
                  <div style={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer>
                      <LineChart
                        data={formattedData}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date"
                          angle={-45}
                          textAnchor="end"
                          height={70}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {historyData?.data ? Array.from(new Set(historyData.data.map(item => item.column_no))).map(columnNo => (
                          <Line
                            key={columnNo}
                            type="monotone"
                            dataKey={`column_${columnNo}`}
                            name={columnGoodsNameMap[columnNo]} // goods_name を表示
                            stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
                            dot={false} // データポイントを非表示にする
                          />
                        )) : null}
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default StocksHistory
